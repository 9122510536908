import { Action, Selector, State, StateContext } from "@ngxs/store";
import { Injectable } from "@angular/core";
import { IntelligentProposalDataType } from "../shared/services/renegotiation.service";

export class SetIntelligentProposal {
    static readonly type = '[IntelligentProposal] Set Intelligent Proposal';
    constructor(public payload: IntelligentProposalDataType) {}
}

export class SetIntelligentProposalFlow2 {
    static readonly type = '[IntelligentProposal] Set Intelligent Proposal Flow2';
    constructor(public payload: IntelligentProposalDataType[]) {}
}

export interface IntelligentProposalStateModel {
    intelligentProposal?: IntelligentProposalDataType;
    intelligentProposalFlow2?: IntelligentProposalDataType[];
}

export interface IntelligentProposalFlow2StateModel {
    intelligentProposalFlow2?: IntelligentProposalDataType[];
}

@State<IntelligentProposalStateModel>({
    name: 'intelligentProposalState',
})
@Injectable()
export class IntelligentProposalState {

    @Selector()
    static getIntelligentProposal(state: IntelligentProposalStateModel) {
        return state.intelligentProposal;
    }

    @Selector()
    static getIntelligentProposalFlow2(state: IntelligentProposalStateModel) {
        return state.intelligentProposalFlow2;
    }

    @Action(SetIntelligentProposal)
    setIntelligentProposal(ctx: StateContext<IntelligentProposalStateModel>, action: SetIntelligentProposal) {
        const state = ctx.getState();
        ctx.setState({
            ...state,
            intelligentProposal: action.payload
        });
    }

    @Action(SetIntelligentProposalFlow2)
    setIntelligentProposalFlow2(ctx: StateContext<IntelligentProposalStateModel>, action: SetIntelligentProposalFlow2) {
        const state = ctx.getState();
        ctx.setState({
            ...state,
            intelligentProposalFlow2: action.payload
        });
    }
}