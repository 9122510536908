import { State, Action, StateContext, Selector } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { ContractDisplayed } from '../shared/types/contract-displayed.type';

export class MarkContractAsDisplayed {
  static readonly type = '[ContractDisplayed] Mark Contract as Displayed';
  constructor(public contractDisplayed: ContractDisplayed) { }
}

export class RemoveDisplayedContract {
  static readonly type = '[ContractDisplayed] Remove Displayed Contract';
  constructor(public contractId: string) { }
}

export interface ContractDisplayedStateModel {
  displayedContracts: ContractDisplayed[];
}

@State<ContractDisplayedStateModel>({
  name: 'contractDisplayedState',
  defaults: {
    displayedContracts: [],
  }
})
@Injectable()
export class ContractDisplayedState {

  @Selector()
  static getDisplayedContracts(state: ContractDisplayedStateModel) {
    return state.displayedContracts;
  }

  @Action(MarkContractAsDisplayed)
  markContractAsDisplayed(ctx: StateContext<ContractDisplayedStateModel>, action: MarkContractAsDisplayed) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      displayedContracts: [...state.displayedContracts, action.contractDisplayed]
    });
  }

  @Action(RemoveDisplayedContract)
  removeDisplayedContract(ctx: StateContext<ContractDisplayedStateModel>, action: RemoveDisplayedContract) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      displayedContracts: state.displayedContracts.filter(contract => contract.contractId !== action.contractId)
    });
  }
}
