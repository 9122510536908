import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-accordion-item',
  templateUrl: './accordion-item.component.html',
  styleUrls: ['./accordion-item.component.scss']
})
export class AccordionItemComponent {

  // @ts-ignore
  @Input() title: string;
  showBody = false;

  toggle() {
    this.showBody = !this.showBody;
  }

}
