import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, Router } from '@angular/router';
import { AlertModalComponent } from '../../style-guide/components/alert-modal/alert-modal.component';
import { CustomDialogService } from '../../style-guide/services/custom-dialog.service';
import { ALERT_MODAL_SESSION_TIMEOUT } from '../../v2/shared/constants/default.constants';
import { AuthService } from '../services/auth.service';
import { CustomClearStatesService } from '../../v2/shared/services/custom-clear-states.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {

  constructor(
    private router: Router,
    private customDialogService: CustomDialogService,
    private authService: AuthService,
    private customClearStatesService: CustomClearStatesService,
  ) { }

  isLoggedOn(): boolean {
    return this.authService.isLoggedOn();
  }

  canActivate()  {
    if (this.isLoggedOn()) {
      return true;
    }
    
    this.handleLogout();
    return false;
  }

  canActivateChild() {
    if (this.isLoggedOn()) {
      return true;
    }

    this.handleLogout();
    return false;
  }

  handleLogout() {
    this.customDialogService.openAlertDialog(AlertModalComponent, undefined, ALERT_MODAL_SESSION_TIMEOUT)
      .subscribe(() => {
        this.authService.logout();
        this.customClearStatesService.stateClear();
        this.router.navigate(['']);
      });
  }

}
