import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { OAuthService } from 'angular-oauth2-oidc';
import { CustomDialogService } from '../../style-guide/services/custom-dialog.service';
import { Router } from '@angular/router';
import { AlertModalComponent } from '../../style-guide/components/alert-modal/alert-modal.component';
import { ALERT_MODAL_API_ERROR, ALERT_MODAL_NETWORK_ERROR, ALERT_MODAL_SESSION_TIMEOUT } from '../../v2/shared/constants/default.constants';
import { AuthService } from './auth.service';
import { CustomClearStatesService } from '../../v2/shared/services/custom-clear-states.service';

@Injectable()
export class WebInterceptor implements HttpInterceptor {

  skipGenericErrors: boolean = false;

  private isLoggingOut = false;

  constructor(
    private oAuthService: OAuthService,
    private authService: AuthService,
    private customDialogService: CustomDialogService,
    private router: Router,
    private customClearStatesService: CustomClearStatesService,
  ) { }

  handleLogout() {
    if (!this.isLoggingOut) {
      this.isLoggingOut = true;
      this.customDialogService.openAlertDialog(AlertModalComponent, undefined, ALERT_MODAL_SESSION_TIMEOUT)
        .subscribe(() => {
          this.authService.logout();
          this.customClearStatesService.stateClear();
          this.router.navigate(['']).then(() => {
            this.isLoggingOut = false;
          });
        });
    }
  }

  handleNetworkError() {
    if (this.skipGenericErrors || this.isLoggingOut) return;
    this.customDialogService.openAlertDialog(AlertModalComponent, undefined, ALERT_MODAL_NETWORK_ERROR);
  }

  handleApiError() {
    return this.customDialogService.openAlertDialog(AlertModalComponent, undefined, ALERT_MODAL_API_ERROR);
  }

  cloneRequestWithAuth(req: HttpRequest<any>): HttpRequest<any> {
    const authToken = this.oAuthService.getAccessToken();
    return req.clone({
      setHeaders: {
        'Authorization': `Bearer ${authToken}`,
        'Content-Type': req.headers.get('Content-Type') || 'application/json'
      }
    });
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const authToken = this.oAuthService.getAccessToken();
    const isUrlKeycloak = /\/auth\/realms\/cliente-omni/i;
    const isUrlRecuperaPdf = /\/recupera\/.*\/pdf/i;
    const isAuthUrl = /^(https:\/\/auth\..*\/auth\/realms\/.*|https:\/\/prd-omni-auth\.omni\.com\.br\/auth.*)$/i;

    const urlKeycloack = isUrlKeycloak.test(req.url);
    const urlRecupera = isUrlRecuperaPdf.test(req.url);
    const urlAuth = isAuthUrl.test(req.url);

    const request = urlRecupera ? req.clone({
      setHeaders: {
        'Content-Type': `application/pdf`,
        'Authorization': `Bearer ${authToken}`
      }
    }) : !urlKeycloack ? this.cloneRequestWithAuth(req) : req;

    this.skipGenericErrors = req.headers.get('skipGenericErrors') === 'true' || urlKeycloack || urlRecupera || urlAuth;

    return next
      .handle(request)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error instanceof HttpErrorResponse) {
            switch (error.status) {
              case 401:
                this.handleLogout();
                break;
              case 0:
                this.handleNetworkError();
                break;
              default:
                if (this.skipGenericErrors || this.isLoggingOut) break;
                if (error.status >= 500 && error.status <= 599 && !this.skipGenericErrors) {
                  return this.handleApiError().pipe(
                    mergeMap(result => {
                      if (result?.primaryPressed) {
                        return next.handle(this.cloneRequestWithAuth(req));
                      } else {
                        return throwError(error);
                      }
                    })
                  );
                }

                break;
            }
          }
          return throwError(error);
        })
      );
  }
}
