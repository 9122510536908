import { State, Action, StateContext, Selector } from '@ngxs/store';
import { Injectable } from '@angular/core';

export class MarkContractAsSent {
  static readonly type = '[SentContracts] Mark Contract as Sent';
  constructor(public contractId: string) {}
}

export class MarkOfferAsSent {
  static readonly type = '[SentOffers] Mark Offer as Sent';
  constructor(public planId: string) {}
}

export class MarkResumeOfferAsSent {
  static readonly type = '[MarkResumeOfferAsSent] Mark Resume Offer as Sent';
  constructor(public contractId: string) {}
}

export class MarkHomeEventAsSent {
  static readonly type = '[MarkHomeEventAsSent] Mark Home Event as Sent';
  constructor(public sent: boolean) {}
}

export class MarkClickBilletActionAsSent {
  static readonly type = '[MarkClickBilletActionAsSent] Mark Click Billet Action as Sent';
  constructor(public id: string) {}
}

export interface AnalyticsStateModel {
  sentContracts: string[];
  sentOffers: string[];
  sentResumeOffers: string[];
  sentClickBilletAction: string[];
  homeEventSent: boolean;
}

@State<AnalyticsStateModel>({
  name: 'analyticsState',
  defaults: {
    sentContracts: [],
    sentOffers: [],
    sentResumeOffers: [],
    sentClickBilletAction: [],
    homeEventSent: false,
  }
})
@Injectable()
export class AnalyticsState {

  @Selector()
  static getSentContracts(state: AnalyticsStateModel) {
    return state.sentContracts;
  }

  @Action(MarkContractAsSent)
  markContractAsSent(ctx: StateContext<AnalyticsStateModel>, action: MarkContractAsSent) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      sentContracts: [...(state.sentContracts || []), action.contractId]
    });
  }

  @Selector()
  static getSentOffers(state: AnalyticsStateModel) {
    return state.sentOffers;
  }

  @Action(MarkOfferAsSent)
  markOfferAsSent(ctx: StateContext<AnalyticsStateModel>, action: MarkOfferAsSent) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      sentOffers: [...(state.sentOffers || []), action.planId]
    });
  }

  @Selector()
  static getResumeOfferAsSent(state: AnalyticsStateModel) {
    return state.sentResumeOffers;
  }

  @Action(MarkResumeOfferAsSent)
  markResumeOfferAsSent(ctx: StateContext<AnalyticsStateModel>, action: MarkResumeOfferAsSent) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      sentResumeOffers: [...state.sentResumeOffers, action.contractId]
    });
  }

  @Selector()
  static getHomeEventAsSent(state: AnalyticsStateModel) {
    return state.homeEventSent;
  }

  @Action(MarkHomeEventAsSent)
  markHomeEventAsSent(ctx: StateContext<AnalyticsStateModel>, action: MarkHomeEventAsSent) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      homeEventSent: action.sent,
    });
  }

  @Selector()
  static getClickBilletActionAsSent(state: AnalyticsStateModel) {
    return state.sentClickBilletAction;
  }

  @Action(MarkClickBilletActionAsSent)
  markClickBilletActionAsSent(ctx: StateContext<AnalyticsStateModel>, action: MarkClickBilletActionAsSent) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      sentClickBilletAction: [...state.sentClickBilletAction, action.id]
    });
  }
}
