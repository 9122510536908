import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { StateClear, StateReset, StateResetAll } from 'ngxs-reset-plugin';
import { ContractDisplayedState } from '../../states/contract-displayed.state';
import { AnalyticsState } from '../../states/analytics.state';
import { SharedState } from '../../states/shared.state';
import { STATES_WITH_RESET_IN_HOME } from '../../../app.module';

@Injectable({
  providedIn: 'root'
})
export class CustomClearStatesService {

  constructor(private store: Store) { }

  stateClear() {
    this.store.dispatch(
      new StateClear(ContractDisplayedState)
    );
  }

	resetStore() {
		this.store.dispatch(
			new StateReset(...STATES_WITH_RESET_IN_HOME)
		);
	}
}