import { State, Action, StateContext, Selector } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { PaymentType } from '../shared/types/renegotiation.type';

export class SetPaymentType {
  static readonly type = '[PaymentType] Set Payment Type';
  constructor(public payload: PaymentType) { }
}

export class ResetPaymentType {
  static readonly type = '[PaymentType] Reset Payment Type';
}
export interface SharedStateModel {
  paymentType?: PaymentType;
}

@State<SharedStateModel>({
  name: 'sharedState',
  defaults: {
    paymentType: undefined,
  }
})
@Injectable()
export class SharedState {

  @Selector()
  static getPaymentType(state: SharedStateModel) {
    return state.paymentType;
  }

  @Action(SetPaymentType)
  setPaymentType(ctx: StateContext<SharedStateModel>, action: SetPaymentType) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      paymentType: action.payload
    });
  }
  @Action(ResetPaymentType)
  resetPaymentType(ctx: StateContext<SharedStateModel>) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      paymentType: undefined
    });
  }
}
