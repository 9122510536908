import { TextHelper } from "../helpers/text.helper";
import { spacings } from "../../../style-guide/tokens";
import { AlertModalData } from "../../../style-guide/components/alert-modal/interfaces/alert-modal-data.interface";

export const DEFAULT_PAGES_TOP_PADDING: number = TextHelper.textPxToNumber(spacings.sLarge);
export const HOME_ROUTE = 'web';
export const HELP_CENTER_ROUTE = `${HOME_ROUTE}/help-center`;
export const PRE_APPROVED_FLOW_ROUTE = `${HOME_ROUTE}/pre-approved-offer-list`;
export const NO_OFFER_FLOW_ROUTE = `${HOME_ROUTE}/no-offer-input-value`;
export const INTELLIGENT_PROPOSAL = `${HOME_ROUTE}/intelligent-proposal`;
export const INTELLIGENT_PROPOSAL_OFFERS_LIST = `${HOME_ROUTE}/intelligent-proposal-offers-list`;
export const INTELLIGENT_PROPOSAL_BEST_OFFER = `${HOME_ROUTE}/intelligent-proposal-best-offer`;
export const COUNTER_PROPOSAL_OFFERS_LIST = `${HOME_ROUTE}/counter-proposal-offers-list`;
export const COUNTER_PROPOSAL_SUMMARY = `${HOME_ROUTE}/counter-proposal-summary`;
export const CONTRACT_DETAIL = `${HOME_ROUTE}/contract-detail`;
export const HOUR_TO_SET_OFFER = 18;

export const ALERT_MODAL_SESSION_TIMEOUT: AlertModalData = {
    title: 'Sua sessão expirou',
    description: 'Para sua segurança a sessão do portal expira depois de um tempo.',
    primaryButtonName: 'Entendi',
    disableClose: true,
};

export const ALERT_MODAL_NETWORK_ERROR: AlertModalData = {
    title: 'Servidor indisponível',
    description: 'Não foi possível conectar com o servidor. Tente novamente mais tarde.',
    primaryButtonName: 'Entendi',
};

export const ALERT_MODAL_API_ERROR: AlertModalData = {
    title: 'Falha de conexão',
    description: 'Não foi possível carregar as dados, por favor tente novamente.',
    primaryButtonName: 'Tentar novamente',
};

export const OPTION_LIST_EXIT_RENEGOTIATION = [
    'Gostei, mas não quero fazer agora',
    'Não gostei, está acima do orçamento',
    'Quero fazer uma nova simulação',
];


export const OPTION_LIST_EXIT_INTELLIGENT_PROPOSAL_RENEGOTIATION = [
    'Gostei, mas não quero fazer agora',
    'Não gostei, está acima do orçamento',
];

