import { Action, Selector, State, StateContext } from "@ngxs/store";
import { Injectable } from "@angular/core";
import { CounterProposalDataType } from "../shared/services/renegotiation.service";

export class SetCounterProposals {
    static readonly type = '[CounterProposal] Set Counter Proposals';
    constructor(public payload: CounterProposalDataType[]) {}
}

export class SetCounterProposal {
    static readonly type = '[CounterProposal] Set Counter Proposal';
    constructor(public payload: CounterProposalDataType) {}
}

export interface CounterProposalsStateModel {
    counterProposals?: CounterProposalDataType[];
    counterProposal?: CounterProposalDataType;
}

@State<CounterProposalsStateModel>({
    name: 'counterProposalsState',
})
@Injectable()
export class CounterProposalState {

    @Selector()
    static getCounterProposal(state: CounterProposalsStateModel) {
        return state.counterProposal;
    }

    @Selector()
    static getCounterProposals(state: CounterProposalsStateModel) {
        return state.counterProposals;
    }

    @Action(SetCounterProposal)
    setCounterProposal(ctx: StateContext<CounterProposalsStateModel>, action: SetCounterProposal) {
        const state = ctx.getState();
        ctx.setState({
            ...state,
            counterProposal: action.payload
        });
    }

    @Action(SetCounterProposals)
    setCounterProposals(ctx: StateContext<CounterProposalsStateModel>, action: SetCounterProposals) {
        const state = ctx.getState();
        ctx.setState({
            ...state,
            counterProposals: action.payload
        });
    }
}